import './App.css';
import React from "react";
import FingerprintJS from '@fingerprintjs/fingerprintjs'

class FPDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fingerprint: ""
    };
  }

  componentDidMount() {    
      const fpPromise = FingerprintJS.load();
      fpPromise
      .then(fp => fp.get())
      .then(result => {
        const fingerprint = result.visitorId
        this.setState({
          fingerprint: fingerprint
        })
      })
  }

  render(params) {
    return <div>
      <p>Your fingerprint is:</p> <h3>{this.state.fingerprint}</h3>
    </div>
  }
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Madinad GDPR info point</h1>

        <p>
          <FPDisplay></FPDisplay>
        </p>
      
      </header>
    </div>
  );
}

export default App;
